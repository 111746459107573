// News Block
// Common News Item
.news-item {
  overflow: hidden;
  color: $white;
  background: $darkest-grey;
  @include breakpoint(l) {
    float: none;
    width: auto;
    min-height: 320px;
  }
}
.news-item-secondary {
  @include breakpoint(l) {
    .news-item-image {
      float: right;
    }
    .news-item-content {
      &:before {
        top: 20px;
        left: auto;
        right: -20px;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 20px solid $darkest-grey;
        border-right: 0;
      }
    }
  }
}
.news-item-third {
    @include breakpoint(l) {
    min-height: 640px;
    .news-item-image,
    .news-item-content {
      float: none;
      width: auto;
    }
    .news-item-image {
      img {
        width: 100%;
        height: 320px;
      }
    }
    .news-item-content {
      &:before {
        top: -20px;
        right: 20px;
        left: auto;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid $darkest-grey;
        border-top: 0;
      }
    }
  }
}
.news-item-image {
  display: block;
  background: $white;
  height: 140px;
  overflow: hidden;
  transition: all 0.3s;
  @include breakpoint(l) {
    height: auto;
  }
  img {
    width: 100%;
    height: auto;
    @include breakpoint(l) {
      width: auto;
      height: 320px;
    }
  }
  &:hover {
    opacity: 0.7;
  }
}
.news-item-content {
  position: relative;
  &:before {
    display: block;
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    top: -20px;
    right: 20px;
    left: auto;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid $darkest-grey;
    border-top: 0;
    @include breakpoint(l) {
      top: 20px;
      left: -20px;
      right: auto;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-right: 20px solid $darkest-grey;
      border-left: 0;
    }
  }
}
.news-item-content-inner { padding: 20px; }
.news-item-title {
  position: relative;
  margin: 0;
  & > a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: $pink;
    }
  }
  &:after {
    content: "";
    width: 60px;
    height: 4px;
    background: $pink;
    display: block;
    margin-top: 5px;
    margin-bottom: 15px;
  }
}
.news-item-publish-date {
  @include font-size(12);
  @include line-height(18);
  color: $pink;
  display: block;
  text-align: right;
}

// News Block Animations
.news-block.active {
  .content-block-inner {
    @include animation(1s);
    animation-delay: 0.2s;
    @extend .fade-in;
  }
}
.news-list.active {
  .news-item {
    @include animation(1s);
    animation-delay: 0.4s;
    @extend .fade-in-left;
  }
  .news-item-secondary {
    @include animation(1s);
    animation-delay: 0.8s;
    @extend .fade-in-right;
  }
  .news-item-third {
    @include animation(1s);
    animation-delay: 1.2s;
    @extend .fade-in-up;
  }
}