// Website Variables and Colors
$base-font-family: Verdana, sans-serif, sans-serif;
$base-font-size: 14;
$base-line-height: 24;
$base-font-weight: 300;
$base-font-color: #555;
$base-link-color: #fe504f;

// Headings
$base-heading-font-family: Verdana, sans-serif, cursive;
$base-h1-font-size: 32;
$base-h1-line-height: 48;
$base-h1-font-weight: 400;
$base-h1-color: #fff;
$base-h2-font-size: 24;
$base-h2-line-height: 28;
$base-h2-font-weight: 700;
$base-h2-color: #343434;
$base-h3-font-size: 16;
$base-h3-line-height: 24;
$base-h3-font-weight: 700;
$base-h3-color: #fe504f;
$base-h4-font-size: 18;
$base-h4-line-height: 24;
$base-h4-font-weight: 400;
$base-h4-color: #fe504f;
$base-h5-font-size: 16;
$base-h5-line-height: 22;
$base-h5-font-weight: 400;
$base-h5-color: #fe504f;
$base-h6-font-size: 14;
$base-h6-line-height: 20;
$base-h6-font-weight: 400;
$base-h6-color: #fe504f;

// Blockquotes
$base-blockquote-font-family: Verdana, sans-serif, cursive;
$base-blockquote-font-size: 18;
$base-blockquote-line-height: 36;
$base-blockquote-color: #fff;
$base-blockquote-cite-color: #fff;

// Forms
$base-input-color: #fff;
$base-input-background-focus-color: transparent;
$base-placeholder-color: #999;

// Colours
$pink: #fe504f;
$light-pink: #fe504f;

$dark-grey: #343434;
$copy-grey: #555;

$darkest-grey: #212121;

$black: #000;
$smoke: #f1f1f1;
$grey: #ccc;
$mid-grey: #777;
$white: #fff;